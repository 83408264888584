import ReactGA4 from 'react-ga4';

import CV from '../../assets/cv.pdf'

const CTA = () => {
    // send google analytics event when user clicks on download cv button
    function sendGA() {
        ReactGA4.event({
            category: 'Button',
            action: 'download_cv_click'
        });
    }
    return (
        <div className="cta">
            <a href={CV} download onClick={sendGA} className='btn'>Download CV</a>
            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
    )
}

export default CTA