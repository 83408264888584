import './header.css'
import CTA from './CTA'
import ME from '../../assets/profile.jpg'
import HeaderSocials from './HeaderSocials'
import { TypeAnimation } from 'react-type-animation';


const Header = () => {
    return (
        <header>
            <div className="container header__container">

                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed out once, initially
                        '~$ whoami',
                        2000,
                        '~$',
                        1000,
                    ]}
                    wrapper="span"
                    speed={1}
                    style={{ fontSize: '1.2em', display: 'inline-block', fontFamily: 'monospace'}}
                    repeat={Infinity}
                />

                {/* <h5 className='whoami'>~$ whoami</h5> */}
                <h1>Davide</h1>
                <h5 className='text-light'>Computer Scientist from Genoa</h5>
                <CTA />
                <HeaderSocials />

                <div className='me'>
                    <img src={ME} alt="Profile" />
                </div>

                <a href="#contact" className='scroll__down'>Scroll Down</a>
            </div>
        </header>
    )
}

export default Header