import './about.css'
import {FaBriefcase} from 'react-icons/fa'  
import {MdSchool} from 'react-icons/md'
import {FaUniversity} from 'react-icons/fa'

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className='container about__container'>
                <div className="about__content">
                    <p>
                    Hi everyone, I'm Davide, a Computer Scientist from Genoa.
                    </p>
                    <div className="about__cards">
                        <article className='about__card'>
                            <MdSchool className='about__icon'/>
                            <h5>2019 - 2022</h5>
                            <small>Bachelor's Degree in Computer Science at UniGE.</small>
                        </article>
                        <article className='about__card'>
                            <FaUniversity className='about__icon'/>
                            <h5>2022 - 2024</h5>
                            <small>Master's Degree in Computer Science (Software Security and Engineering) at UniGE.</small>
                        </article>
                        <article className='about__card'>
                            <FaBriefcase className='about__icon'/>
                            <h5>2024 - Now</h5>
                            <small>Consultant at Cluster Reply.</small>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About