import './experience.css'

const Experience = () => {
    return (
        <section id='experience'>
            <h5>What Skills I Have</h5>
            <h2>My Experience</h2>

            <div className="container experience__container">
                <div className="experiences">
                    <div className="experience__content">
                        <article className='experience__details'>
                            <div>
                                <h4>Scripting</h4>
                                <small className='text-light'>Python, Bash</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <div>
                                <h4>Mobile Application Development</h4>
                                <small className='text-light'>Java (Android), Swift/SwitUi (iOS), React Native</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <div>
                                <h4>Frontend Development</h4>
                                <small className='text-light'>HTML, CSS, JavaScript, TypeScript, React.js</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <div>
                                <h4>Database</h4>
                                <small className='text-light'>MongoDB, Firebase, PostgreSQL</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <div>
                                <h4>Backend Development</h4>
                                <small className='text-light'>Node.js, PHP</small>
                            </div>
                        </article>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Experience