import {BsLinkedin} from 'react-icons/bs'  
import {FaGithub} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'

const HeaderSocials = () => {
    return (
        <div className='header__socials'>
            <a href="https://www.linkedin.com/in/davide-scarra/"><BsLinkedin/></a>
            <a href="https://github.com/LoScarro" target=""><FaGithub/></a>
            <a href="https://www.instagram.com/lo_scarro/"><FiInstagram/></a>
        </div>
    )
}

export default HeaderSocials