import './involved.css'

import zenhack_logo from '../../assets/ZenHack.png'
// import CC from '../../assets/cyberchallenge.jpg'
import cyberchallenge from '../../assets/cyberchallenge.jpg'
import reply_logo from '../../assets/reply_cluster_logo.jpg'

// import React, { useState, useEffect } from 'react';

const Involved = () => {
    return (
        <section id='involved'>

            <h5>What am I doing now?</h5>
            <h2>Current Involved in</h2>
            <div className='container involved__container'>

                <article className='involved__item'>
                    <div className="involved__item-image">
                        <img src={reply_logo} alt="Cluster Reply Logo" />
                    </div>
                    <h3>Cluster Reply</h3>
                    <p>
                        Currently working as a Consultant at Cluster Reply, an esteemed technology consultancy within the Reply group, specializes in Microsoft Azure solutions and services.
                    </p>
                    <div className="involved__item-cta">
                        <a href="https://www.reply.com/cluster-reply/en/" className='btn'>Official Website</a>
                    </div>
                </article>

                <article className='involved__item'>
                    <div className="involved__item-image">
                        <img src={zenhack_logo} alt="ZenHack Team" />
                    </div>
                    <h3>ZenHack Team</h3>
                    <p>
                        I often participate in Capture The Flag (CTF) events with ZenHack,
                        a Zena-based CTF & cybersecurity team established in 2017 by a group
                        of cybersecurity enthusiasts comprising students and researchers.
                    </p>
                    <div className="involved__item-cta">
                        <a href="https://zenhack.it/" className='btn'>Official Website</a>
                    </div>
                </article>

                <article className="involved__item">
                    <div className="involved__item-image">
                        <img src={cyberchallenge} alt="CyberChallenge Team" />
                    </div>
                    <h3>CyberChallenge Program</h3>
                    <p>
                        I'm currently serving as a Local Organizer for the University of Genova
                        in the CyberChallenge Program, which aims to identify, attract, train,
                        recruit, and place the next generation of cybersecurity professionals
                        into the workforce.
                    </p>
                    <div className="involved__item-cta">
                        <a href="https://cyberchallenge.it/who" className="btn">
                            Official Website
                        </a>
                    </div>
                </article>

                {/* <article className="involved__item">
                    <div className="involved__item-image">
                        <img src={cyberchallenge} alt="CyberChallenge Team"/>
                    </div>
                    <h3>Tutor d'accoglienza at UniGe</h3>
                    <p>
                    I am currently helping new students get oriented in their new
                    University life. I work with teachers and staff all year round,
                    both in classrooms and at events where universities showcase
                    themselves to prospective students.
                    </p>
                    <div className="involved__item-cta">
                        <a href="https://cyberchallenge.it/who" className="btn">
                            Official Website
                        </a>
                    </div>
                </article> */}

            </div>
        </section>
    )
}

export default Involved