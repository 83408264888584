import React, { useEffect } from 'react';

import './notes.css'

import BD from './previews/bd.png';
import LPO from './previews/lpo.png';
import DC from './previews/dc.png';
import NA from './previews/na.png';
import ASD from './previews/asd.png';
import PCAD from './previews/pcad.png';
import FIS from './previews/fis.png';
import APA from './previews/apa.png';
import BASC from './previews/basc.png';
import DF from './previews/df.png';
import FESI from './previews/fesi.png';
import ALAN from './previews/alan.png';
import SETI from './previews/seti.png';

function Notes() {

    // change the page title
    useEffect(() => {
        document.title = 'Notes';
    }, []);

    return (
        <section id='notes'>
            <h2>Our Legacy</h2>
            <h4>Please avoid altering any of these materials. They have been meticulously curated and crafted with dedication and love.<br />
                To access the notes, you need to request access via Google Docs and wait for it to be granted.</h4>
            <div className='container notes__container'>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={ASD} alt="Algoritmi e Strutture Dati" />
                    </div>

                    <p>Algoritmi e Strutture Dati (A.A. 2019/2020)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1OjW2k1VWTut_diZhCCbW1SZplUSLjilKvDOt1YrfUi0/edit?usp=sharing" className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={BD} alt="Basi di Dati" />
                    </div>

                    <p>Basi di Dati (A.A. 2020/2021)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1e8WGWhO0tJjxOEevhRMkv1lKZu5LyFk99lPZGJzLnMg/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={ALAN} alt="Basi di Dati" />
                    </div>

                    <p>Algebra Lineare e Analisi Numerica (A.A. 2020/2021)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1m0cFz6kAGNL_KIHqLR6OTpjaqIcKYAMufD4DKtX3tmw/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={SETI} alt="Basi di Dati" />
                    </div>

                    <p>Sistemi di Elaborazione e Trasmissione dell'informazione (A.A. 2020/2021)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1Y2uF3KGBYXBLVbtLludk3_jft_-fiN8nwc1XEFG6SeU/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={LPO} alt="Linguaggi e Programmazione Orientata agli Oggetti" />
                    </div>

                    <p>Linguaggi e Programmazione Orientata agli Oggetti (A.A. 2020/2021)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/15sOikIUwtL67tlDm8K8hQQz-yrBsPgTww_E1l0u-oSk/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={APA} alt="Analisi e Progettazione di Algoritmi" />
                    </div>

                    <p>Analisi e Progettazione di Algoritmi (A.A. 2020/2021)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1Bmi38K1G0Fp8gEQvxRvJXewd7X7KyoK4JjjfQx0KBHg/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={FIS} alt="Fondamenti di Ingegneria del Software" />
                    </div>

                    <p>Fondamenti di Ingegneria del Software (A.A. 2021/2022)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1LMmM8TG-InwImTa7gSMWwMu4EFAcFi4wV3afFAte-J0/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={PCAD} alt="Programmazione Concorrente e Algoritmi Distribuiti" />
                    </div>

                    <p>Programmazione Concorrente e Algoritmi Distribuiti (A.A. 2021/2022)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1OL7hOnvr9wb7FRPNETtnMF_BNaklIs9_slSoV_heMks/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={FESI} alt="Fondamenti di Elaborazione di Segnali e Immagini" />
                    </div>

                    <p>Fondamenti di Elaborazione di Segnali e Immagini (A.A. 2021/2022)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1OsKgAK21AlTqQCynllQQHDW9ZO1dFspqzPltuFBixTI/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={DF} alt="Digital Forensics" />
                    </div>

                    <p>Digital Forensics (A.A. 2022/2023)</p>

                    <div className="notes__item-cta">
                        <a href="https://adjoining-snowstorm-a80.notion.site/Digital-Forensics-8eae68b5e663420987850501eda79671?pvs=4" download className='btn'>Notion</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={NA} alt="Network Analysis" />
                    </div>

                    <p>Network Analysis (A.A. 2022/2023)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1zVWOimnfsCxQfS_nApSvMdWungvkTknUajxL6jjy-hg/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={DC} alt="Distributed Computing" />
                    </div>

                    <p>Distributed Computing (A.A. 2022/2023)</p>

                    <div className="notes__item-cta">
                        <a href="https://docs.google.com/document/d/1wWzxSJ__aLgJEeva4JNksniPNRq1MkxGeI4VaXgKmew/edit?usp=sharing" download className='btn'>Google Docs</a>
                    </div>
                </article>

                <article className='notes__item'>
                    <div className="notes__item-image">
                        <img src={BASC} alt="Binary Analysis and Secure Coding" />
                    </div>

                    <p>Binary Analysis and Secure Coding (A.A. 2023/2024)</p>

                    <div className="notes__item-cta">
                        <a href="https://adjoining-snowstorm-a80.notion.site/BASC-4ed92284f3374486815c0a19d06e766f?pvs=4" download className='btn'>Notion</a>
                    </div>
                </article>
            </div>
            <h5>Made with &lt;3 for my brothers at unige.</h5>
        </section>
    );
}

export default Notes;
