import React, { useEffect } from 'react';

import './clips.css'

import CAMO_B2B from './mp4/camo_b2b.mp4';
import LAG_B2B from './mp4/lag_b2b.mp4';
import OVSD_B2B from './mp4/ovsd_b2b.mp4';
import SWAT from './mp4/swat.mp4';

function Clips() {

    // change the page title
    useEffect(() => {
        document.title = 'Clips';
    }, []);

    return (
        <section id='clips'>
            <h2>My Clips</h2>
            <h4>Actually, I'm also very skilled at Halo</h4>
            <div className='container clips__container'>

                <article className='clips__item'>
                    <video controls>
                        <source src={CAMO_B2B} type="video/mp4" />
                    </video>
                    <p>The camo won't protect you from my ninja attack.</p>
                </article>

                <article className='clips__item'>
                    <video controls>
                        <source src={OVSD_B2B} type="video/mp4" />
                    </video>
                    <p>Not even the overshield.</p>
                </article>

                <article className='clips__item'>
                    <video controls>
                        <source src={LAG_B2B} type="video/mp4" />
                    </video>
                    <p>Despite the lag.</p>
                </article>

                <article className='clips__item'>
                    <video controls>
                        <source src={SWAT} type="video/mp4" />
                    </video>
                    <p>Swatting.</p>
                </article>

            </div>
        </section>
    );
}

export default Clips;
